module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lou Guarini Plumbing & Heating","short_name":"Jersey City Plumbers","start_url":"/","background_color":"#29355f","theme_color":"#29355f","display":"minimal-ui","icon":"src/images/guarini-plumbing-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c489fec392392c1f2a73f4b3d34877c4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
